/* CouponListing.css */

/* Track */
::-webkit-scrollbar {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8ebbfe;
  border-radius: 10px;
}
